<template>
  <v-sheet>
    <cit-data-table
      :fixed-filters="{
        category_id:$route.params.categoryId
      }"
      :headers="headers"
      :includes="[
        'cit_attribute',
        'cit_attribute.group'
      ]"
      resource-path="feed-connectors/nod/attribute-names"
    >

      <template v-slot:actions>
        <go-back-button :category-id="$route.params.categoryId"/>
      </template>

      <template v-slot:item.name="{item, value}">
        <div class="font-weight-bold">
          {{ value }}
        </div>
        <div class="italic font-weight-light">
          ex. {{ item.example_value }}
        </div>
      </template>

      <template v-slot:item.actions="{item}">
        <edit-form :item="item"/>
        <view-button
          v-if="item.cit_attribute_id"
          :to="{name:'feed-connectors-nod-attributes-values', params:{attributeId:item.cit_attribute_id, categoryId:$route.params.categoryId}}"
          target="_self"
          text="Vezi atributele"
        />

      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import GoBackButton from '@/views/FeedConnectors/Nod/NodAttributes/GoBackButton'
import EditForm from './Edit'
import ViewButton from '@/components/general-form/ViewButton'

export default {
  components: {
    ViewButton,
    GoBackButton,
    CitDataTable,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Atribut Nod',
          value: 'name',
          filterType: 'text'
        },
        {
          text: 'Atribut CIT',
          value: 'cit_attribute.name',
          filterType: 'text'
        },
        {
          text: 'Grupa Atribut CIT',
          value: 'cit_attribute.group.name'
        }
      ]
    }
  }
}
</script>
