<template>
  <v-autocomplete
    v-model="selectOption"
    :items="attributes"
    :label="label"
    :loading="loading"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    dense
    hide-details
    hide-no-data
    item-text="full_name"
    item-value="id"
    no-filter
    outlined
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'AttributeSelector',
  props: {
    value: {},
    returnObject: {
      default: false
    },
    label: {
      default: 'Atribute'
    },
    categoryId: {
      default: false
    }
  },
  data () {
    return {
      paginatedAttributes: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    attributes () {
      return this.paginatedAttributes.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      let extraFilters = ''

      if (this.categoryId) {
        extraFilters = '&filter[product_category_id]=' + this.categoryId
      }
      this.$http.get(`/product-attributes?append=full_name&filter[name]=${search}${extraFilters}`)
        .then(({data}) => {
          this.paginatedAttributes = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
