<template>
  <span class="button-wrapper">
    <v-btn
      v-on="listeners"
      :to="to"
      color=" lighten-4"
      elevation="1"
      class="mx-1 mb-2 w-full secondary-button"
    >
      <v-icon class="mr-2" small>fal fa-plus</v-icon>
      <slot>{{ buttonLabel }}</slot>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'NewButton',
  props: {
    to: {
      default: '',
      type: String | Object
    },
    buttonLabel: {
      default: 'Adauga',
      type: String
    }
  },
  methods: {},
  computed: {
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>

<style scoped>
.button-wrapper {
  @apply inline-block
}
</style>
