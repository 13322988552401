<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="{...on,...listeners}"
        :href="href"
        :target="target"
        :to="to"
        color="dark"
        icon
      >
        <v-icon>fal fa-eye</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ViewButton',
  props: {
    to: {
      default: '',
      type: String | Object
    },
    target: {
      default: '_BLANK'
    },
    href: {
      default: '',
      type: String | Object
    },
    text: {
      default: 'Vizualizare',
      type: String
    }
  },
  methods: {},
  computed: {
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>
