<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :to="{name:'feed-connectors-nod-integration', params:{categoryId:categoryId}}"
        class="mx-2 mb-2"
        elevation="1"
      >
        <v-icon class="mr-2" small>fal fa-angle-left</v-icon>
        Inapoi
      </v-btn>
    </template>
    <span>Inapoi</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    categoryId: {
      required: true
    }
  }
}
</script>

