<template>
  <action-edit
    :action-url="`feed-connectors/nod/attribute-names/${item.id}`"
    :item="item"
    button-label="Editeaza Legatura de Atribut"
  >

    <template v-slot:title>
      Editeaza legatura atributului: <strong>{{ item.name }}</strong>
    </template>

    <template v-slot:fields="{form, errors}">
      <div class="italic font-weight-light mb-4">
        Exemplu de valoare: "{{ item.example_value }}"
      </div>

      <div>
        <attribute-selector v-model="form.cit_attribute_id" :category-id="item.category_id"/>
        <error-messages :errors="errors.cit_attribute_id"/>
      </div>

    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import AttributeSelector from '@/components/autocompletes/AttributeSelector'

export default {
  components: {
    AttributeSelector,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
